
class MedicamentoModel{
    constructor() {
        
    }
    
    static Clear(){  
        let result = {
            disponivel: true,
            dose: '',
            idPaciente: '',
            isDependente: '',
            nome: '',
            tipo: '',
            url: '',
            observacao: '',
        }
        return result;
    }

    static ToDB(medicamento){
        let result = {
            dose: medicamento.dose,
            idPaciente: medicamento.idPaciente,
            isDependente: medicamento.isDependente,
            nome: medicamento.nome,
            tipo: medicamento.tipo,
            url: medicamento.url,
            observacao: medicamento.observacao,
            disponivel: medicamento.disponivel,
        }
        return result;
    }

    static FromDB(id, docData){ 
        //console.log(docData)
        let medicamento = {
            id : id,
            dose: docData.dose,
            idPaciente: docData.idPaciente,
            isDependente: docData.isDependente,
            nome: docData.nome,
            tipo: docData.tipo,
            url: docData.url,
            observacao: docData.observacao,
            disponivel: docData.disponivel
        }
        return medicamento;
    }
    
}
export default MedicamentoModel;