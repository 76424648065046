import firebase from '../config/firebase';
import Cripto from '../components/cripto'
import 'firebase/auth';
import 'firebase/firestore';

import { store } from '../index.js'

export const COMORBIDADES_LISTAR = 'COMORBIDADES_LISTAR';
const comorbidadesListar = (c) => ({
    type: COMORBIDADES_LISTAR,
    comorbidades: c,
});

export const LISTAR_COMORBIDADES_SELECT = 'LISTAR_COMORBIDADES_SELECT';
const listarComorbidadesSelect = (field, value) => ({
    type: LISTAR_COMORBIDADES_SELECT,
    field,
    value,
});


export const COMORBIDADE_SET_FIELD = 'COMORBIDADE_SET_FIELD';
export const comorbidadeSetField = (field, value) => {
    return {
        type: COMORBIDADE_SET_FIELD,
        field,
        value,
    }
}

export const COMORBIDADE_SET_CURRENT = 'COMORBIDADE_SET_CURRENT';
const comorbidadeSetCurrent = perfil => ({
    type: COMORBIDADE_SET_CURRENT,
    perfil
});



export const comorbidadeSetAtual = (id) => dispatch => {

    if (id == null) {
        var perfil = [

        ]
        dispatch(comorbidadeSetCurrent(perfil));
    } else {
        var currentValue = store.getState().perfis;
        let perfil = '';


        currentValue.map((item) => {
            if (item.id === id) {
                perfil = item;
            }
        });
        dispatch(comorbidadeSetCurrent(perfil));
    }

}

export const comorbidadeCriar = (nomePerfil, menu, submenu) => {
    var db = firebase.firestore();
    try {
        db.collection('perfilWeb').doc(nomePerfil).set({
            menu: menu,
            submenu: submenu,
            tipo: nomePerfil
        });
        return true;
    } catch (e) {
        return false;
    }
}

export const comorbidadeAtualizar = (nomePerfil, menu, submenu) => {
    var db = firebase.firestore();
    try {
        db.collection('perfilWeb').doc(nomePerfil).update({
            menu: menu,
            submenu: submenu,
            tipo: nomePerfil
        });

        return true;
    } catch (e) {
        return false;
    }
}



export const comorbidadeGetPerfil = async (id) => {
    var snapshot = await firebase.firestore().collection('perfilWeb').doc(id).get();
    let tt = {}
    tt = {
        id: snapshot.id,
        menu: snapshot.data().menu,
        submenu: snapshot.data().submenu
    }
    return tt;
}
export const comorbidadeGetUser = async (perfil, cpf, admin) => {
    try {
        if (admin) {
            return await new Promise(async (resolve, reject) => {
                try {
                    let query = await firebase.firestore().collection('medicos').where('cpf', '==', cpf).limit(1).get();
                    let item = [];
                    let itemWithIDPerfil = [];
                    if (!query.empty) {
                        query.docs.map(
                            doc => {
                                let docId = {
                                    id: doc.id,
                                    nome: doc.data().nome
                                }
                                item.push(docId)
                            },
                        );
                        let queryUsuario = await firebase.firestore().collection('usuarios').doc(item[0].id).collection('perfilWeb').get();
                        if (!queryUsuario.empty) {
                            queryUsuario.docs.map(
                                doc => {
                                    item.forEach(item => {
                                        let docPerfil = {
                                            id: item.id,
                                            nome: item.nome,
                                            idPerfil: doc.id
                                        }
                                        itemWithIDPerfil.push(docPerfil)
                                    })
                                },
                                resolve(itemWithIDPerfil)
                            )
                        } else {
                            item.forEach(item => {
                                let docPerfil = {
                                    id: item.id,
                                    nome: item.nome,
                                    idPerfil: ''
                                }
                                itemWithIDPerfil.push(docPerfil)
                                resolve(itemWithIDPerfil)
                            })
                        }
                    } 
                    query = await firebase.firestore().collection('administradores').where('cpf', '==', cpf).limit(1).get();
                    item = [];
                    itemWithIDPerfil = [];
                    if (!query.empty) {
                        query.docs.map(
                            doc => {
                                let docId = {
                                    id: doc.id,
                                    nome: doc.data().nome
                                }
                                item.push(docId)
                            },
                        );
                        let queryUsuario = await firebase.firestore().collection('usuarios').doc(item[0].id).collection('perfilWeb').get();
                        if (!queryUsuario.empty) {
                            queryUsuario.docs.map(
                                doc => {
                                    item.forEach(item => {
                                        let docPerfil = {
                                            id: item.id,
                                            nome: item.nome,
                                            idPerfil: doc.id
                                        }
                                        itemWithIDPerfil.push(docPerfil)
                                    })
                                },
                                resolve(itemWithIDPerfil)
                            )
                        } else {
                            item.forEach(item => {
                                let docPerfil = {
                                    id: item.id,
                                    nome: item.nome,
                                    idPerfil: ''
                                }
                                itemWithIDPerfil.push(docPerfil)
                                resolve(itemWithIDPerfil)
                            })
                        }
                    }
                    query = await firebase.firestore().collection('operadores').where('cpf', '==', cpf).limit(1).get();
                    item = [];
                    itemWithIDPerfil = [];
                    if (!query.empty) {
                        query.docs.map(
                            doc => {
                                let docId = {
                                    id: doc.id,
                                    nome: doc.data().nome
                                }
                                item.push(docId)
                            },
                        );
                        let queryUsuario = await firebase.firestore().collection('usuarios').doc(item[0].id).collection('perfilWeb').get();
                        if (!queryUsuario.empty) {
                            queryUsuario.docs.map(
                                doc => {
                                    item.forEach(item => {
                                        let docPerfil = {
                                            id: item.id,
                                            nome: item.nome,
                                            idPerfil: doc.id
                                        }
                                        itemWithIDPerfil.push(docPerfil)
                                    })
                                },
                                resolve(itemWithIDPerfil)
                            )
                        } else {
                            item.forEach(item => {
                                let docPerfil = {
                                    id: item.id,
                                    nome: item.nome,
                                    idPerfil: ''
                                }
                                itemWithIDPerfil.push(docPerfil)
                                resolve(itemWithIDPerfil)
                            })
                        }
                    }
                    reject('Usuário não encontrado')
                    
                } catch (error) {
                    reject('Usuário não encontrado')
                }
            })
        } else {
            return await new Promise(async (resolve, reject) => {
                try {
                    let query = await firebase.firestore().collection(perfil).where('cpf', '==', cpf).limit(1).get();
                    let item = [];
                    let itemWithIDPerfil = [];
                    if (!query.empty) {
                        query.docs.map(
                            doc => {
                                let docId = {
                                    id: doc.id,
                                    nome: doc.data().nome
                                }
                                item.push(docId)
                            },
                        );
                        let queryUsuario = await firebase.firestore().collection('usuarios').doc(item[0].id).collection('perfilWeb').get();
                        if (!queryUsuario.empty) {
                            queryUsuario.docs.map(
                                doc => {
                                    item.forEach(item => {
                                        let docPerfil = {
                                            id: item.id,
                                            nome: item.nome,
                                            idPerfil: doc.id
                                        }
                                        itemWithIDPerfil.push(docPerfil)
                                    })
                                },
                                resolve(itemWithIDPerfil)
                            )
                        } else {
                            item.forEach(item => {
                                let docPerfil = {
                                    id: item.id,
                                    nome: item.nome,
                                    idPerfil: ''
                                }
                                itemWithIDPerfil.push(docPerfil)
                                resolve(itemWithIDPerfil)
                            })
                        }
                    } else {
                        reject('Usuário não encontrado')
                    }
                } catch (error) {
                    reject('Usuário não encontrado')
                }
            })
        }
    } catch (error) {
    }
}


export const comorbidadesPorUsuario = async (idUsuario, idPerfilWebAtual, idPerfilWeb, menu, submenu) => {
    try {
        return await new Promise((resolve, reject) => {
            if (idPerfilWebAtual != "") {
                firebase.firestore()
                    .collection('usuarios')
                    .doc(idUsuario)
                    .collection('perfilWeb')
                    .doc(idPerfilWebAtual).delete().then((success) => {
                        firebase.firestore()
                            .collection('usuarios')
                            .doc(idUsuario)
                            .collection('perfilWeb')
                            .doc(idPerfilWeb)
                            .set({
                                menu: menu,
                                submenu: submenu,
                                tipo: idPerfilWebAtual
                            }).then((success) => {
                                resolve('sucesso')
                            }).catch((error) => {
                                reject('error')
                            });
                    }).catch((error) => {
                        reject('error')
                    })
            } else {
                firebase.firestore()
                    .collection('usuarios')
                    .doc(idUsuario)
                    .collection('perfilWeb')
                    .doc(idPerfilWeb)
                    .set({
                        menu: menu,
                        submenu: submenu,
                    }).then((success) => {
                        resolve('sucesso')
                    }).catch((error) => {
                        reject('error')
                    });
            }
        });
    } catch (error) {
    }
}




export const comorbidadesPerfilTodosUsuario = async (idPerfilWebAtual, menu, submenu) => {
    try {
        let querySnapshot = await firebase.firestore().collectionGroup('perfilWeb').where('tipo', '==', idPerfilWebAtual).get();
        querySnapshot.forEach((doc) => {
            doc.ref.set({
                menu: menu,
                submenu: submenu,
                tipo: idPerfilWebAtual
            })
        });

    } catch (error) {
    }
}



export const comorbidadeDeletePorUsuario = async (idUsuario, idPerfilWeb) => {
    try {
        return await new Promise((resolve, reject) => {

        });
    } catch (error) {

    }
}

export const comorbidadeGetListaToGrid = () => {
    return async dispatch => {
        var id = Cripto.DecryptedStorage('uid');
        var snapshot = await firebase.firestore().collection('antecedentes').get();
        var item = [];
        snapshot.docs.map(doc => {
            if(doc.data().ativo === true){
                let tt = {
                    id: doc.id,
                    antecedentes: doc.data().antecedente,
                    ativo : doc.data().ativo & "",
                    descricao : ''
                }
                item.push(tt);
            }
        });
        dispatch(comorbidadesListar(item));
    }
}


export const comorbidadeCarregaListaDisponiveis = (that) => {
    try {
        return async dispatch => {
            try {
                var snapshot = await firebase.firestore().collection('antecedentes').get();
                var comorbidades = [];
                snapshot.docs.map(doc => {
                    if(doc.data().ativo === true){
                        let tt = {
                            id: doc.id,
                            antecedente: doc.data().antecedente,
                            ativo : true,
                        }
                        comorbidades.push(tt);
                    }
                });
                that(comorbidades);
                dispatch(comorbidadesListar(comorbidades));
            } catch (e) {
                console.log('comorbidadeCarregaListaDisponiveis ERROR');
                console.log(e.message);
            }
        }
    } catch (error) {
        console.log('comorbidadeCarregaListaDisponiveis ERROR 2');
        console.log(error.message);
    }
}


export const comorbidadesFromIdDependente = async (idPaciente, idDependente, that) => {
        try {
            var snapshot = await firebase.firestore().collection('pacientes').doc(idPaciente).collection('dependentes').doc(idDependente).collection('antecedentes').get();
            var comorbidades = [];
            snapshot.docs.map(doc => {
                if(doc.data().ativo === true){
                    let tt = {
                        id: doc.id,
                        antecedente: doc.data().antecedente,
                        ativo : doc.data().ativo === undefined ? false : doc.data().ativo,
                        isSelect: doc.data().isSelect === undefined ? false : doc.data().isSelect,
                    }
                    comorbidades.push(tt);
                }
            });
            console.log('comorbidadesFromIdDependente')
            console.log(comorbidades)
            that(comorbidades);
        } catch (e) {
            console.log('comorbidadesFromIdDependente ERROR');
            console.log(e.message);
        }
        
    
}

export const comorbidadesFromTitular = async (idPaciente, that) => {
    try {
        var snapshot = await firebase.firestore().collection('pacientes').doc(idPaciente).collection('antecedentes').get();
        var comorbidades = [];
        console.log(idPaciente)
        snapshot.docs.map(doc => {
            if(doc.data().ativo === true){
                let tt = {
                    id: doc.id,
                    antecedente: doc.data().antecedente,
                    ativo : doc.data().ativo === undefined ? false : doc.data().ativo,
                    isSelect: doc.data().isSelect === undefined ? false : doc.data().isSelect,
                }
                comorbidades.push(tt);
            }
        });
        console.log(comorbidades)
        that(comorbidades);
    } catch (e) {
        console.log(e)
        console.log('comorbidadesFromTitular ERROR');
        console.log(e.message);
    }
}


export const comorbidadeSalvarTitular = async (idPaciente, comorbidades) => {
    try {
        var db = firebase.firestore();

        const batch = db.batch();
        var result = await db.collection('pacientes').doc(idPaciente).collection('antecedentes').get();
        result.docs.map(doc => {
            batch.delete(doc.ref);
        });
        await batch.commit();

        var t = 0;
        for(t = 0; t < comorbidades.length; t++)
        {
            var result = await db.collection('pacientes').doc(idPaciente).collection('antecedentes').doc(comorbidades[t].id).set(
                comorbidades[t]
            )
        }
        
        console.log('comorbidadeSalvarTitular')
        return true;
        
    } catch (e) {
        console.log('comorbidadeSalvarTitular ERROR');
        console.log(e.message);
        return false;
    }
}



export const comorbidadeSalvarDependente = async (idPaciente, idDependente, comorbidades) => {
    try {
        var db = firebase.firestore();

        const batch = db.batch();
        var result = await db.collection('pacientes').doc(idPaciente).collection('dependentes').doc(idDependente).collection('antecedentes').get();
        result.docs.map(doc => {
            batch.delete(doc.ref);
        });
        await batch.commit();

        var t = 0;
        for(t = 0; t < comorbidades.length; t++)
        {
            var result = await db.collection('pacientes').doc(idPaciente).collection('dependentes').doc(idDependente).collection('antecedentes').doc(comorbidades[t].id).set(
                comorbidades[t]
            )
        }
        
        console.log('comorbidadeSalvarDependente')
        return true;
        
    } catch (e) {
        console.log('comorbidadeSalvarDependente ERROR');
        console.log(e.message);
        return false;
    }
}




