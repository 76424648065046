
import firebase from '../config/firebase';
import 'firebase/auth';
import 'firebase/firestore';
import { list } from 'rxfire/database';
import { collectionData, collectionChanges } from 'rxfire/firestore';
import { tap } from 'rxjs/operators';
import { storage } from "firebase";
import { fromCollectionRef } from 'rxfire/firestore';
import Cripto from '../components/cripto'
import { getIdToken, request } from '.';

export const CHAT_MESSAGES = 'CHAT_MESSAGES';
export const chatMessages = (msgs) => {
    return {
        type: CHAT_MESSAGES,
        chat: msgs,
    }
}

export const observableMessage = (pacienteId, atendimentoId, isEnfermeiro, that, fromConsultaScreen) => dispatch => {
    if (pacienteId === undefined || pacienteId == ''
        || atendimentoId === undefined || atendimentoId == '') {

    } else {
        if (isEnfermeiro) {
            const collectionRef = firebase.firestore().collection('pacientes').doc(pacienteId).collection('atendimentos').doc(atendimentoId).collection('messagesEnfermeiro');
            collectionData(collectionRef, 'uid')
                .subscribe(msgs => {
                    var listMessagens = [];
                    msgs.map(doc => {
                        let msg = {
                            idFrom: doc.idFrom,
                            idTo: doc.idTo,
                            content: doc.content,
                            notifica: doc.notifica,
                            notificacaoChat: doc.notificacaoChat,
                            replica: doc.replica,
                            timestamp: doc.timestamp,
                            type: doc.type,
                        };
                        listMessagens.push(msg)
                    });
                    dispatch(chatMessages(listMessagens));
                    try {
                        if (fromConsultaScreen) {
                            if (listMessagens[listMessagens.length - 1].idFrom !== pacienteId) {
                                that.getTotalMensagemChat()
                            }
                        } else {
                            that.callBackScroll(listMessagens[listMessagens.length - 1]);
                        }
                    } catch (error) {
                        console.log(error)
                    }
                });
        } else {
            console.log('here')
            const collectionRef = firebase.firestore().collection('pacientes').doc(pacienteId).collection('atendimentos').doc(atendimentoId).collection('messagesMedico');
            collectionData(collectionRef, 'uid')
                .subscribe(msgs => {
                    var listMessagens = [];
                    msgs.map(doc => {
                        let msg = {
                            idFrom: doc.idFrom,
                            idTo: doc.idTo,
                            content: doc.content,
                            notifica: doc.notifica,
                            notificacaoChat: doc.notificacaoChat,
                            replica: doc.replica,
                            timestamp: doc.timestamp,
                            type: doc.type,
                        };
                        listMessagens.push(msg)
                    });
                    dispatch(chatMessages(listMessagens));
                    try {
                        if (fromConsultaScreen) {
                            if (listMessagens[listMessagens.length - 1].idFrom !== pacienteId) {
                                that.getTotalMensagemChat()
                            }
                        } else {
                            that.callBackScroll(listMessagens[listMessagens.length - 1]);
                        }
                    } catch (error) {
                        console.log(error)
                    }
                });
        }
    }
}


export const observableMessageCallBack = (pacienteId, atendimentoId, status, that) => {
    if (pacienteId === undefined || pacienteId == ''
        || atendimentoId === undefined || atendimentoId == '') {

    } else {
        if (status < 3) {
            const collectionRef = firebase.firestore().collection('pacientes').doc(pacienteId).collection('atendimentos').doc(atendimentoId).collection('messagesEnfermeiro');
            collectionData(collectionRef, 'uid')
                .subscribe(msgs => {
                    var listMessagens = [];
                    msgs.map(doc => {
                        let msg = {
                            idFrom: doc.idFrom,
                            idTo: doc.idTo,
                            content: doc.content,
                            notifica: doc.notifica,
                            notificacaoChat: doc.notificacaoChat,
                            replica: doc.replica,
                            timestamp: doc.timestamp,
                            type: doc.type,
                        };
                        listMessagens.push(msg)
                    });
                    that.callbackMessageChat(listMessagens);
                });
        } else {
            const collectionRef = firebase.firestore().collection('pacientes').doc(pacienteId).collection('atendimentos').doc(atendimentoId).collection('messagesMedico');
            collectionData(collectionRef, 'uid')
                .subscribe(msgs => {
                    var listMessagens = [];
                    msgs.map(doc => {
                        let msg = {
                            idFrom: doc.idFrom,
                            idTo: doc.idTo,
                            content: doc.content,
                            notifica: doc.notifica,
                            notificacaoChat: doc.notificacaoChat,
                            replica: doc.replica,
                            timestamp: doc.timestamp,
                            type: doc.type,
                        };
                        listMessagens.push(msg)
                    });
                    that.callbackMessageChat(listMessagens);
                });
        }
    }
}

export const chatAddMessageTriagem = async (medicoId, pacienteId, atendimentoId, msg) => {
    return await new Promise(async (resolve, reject) => {
        console.log(msg)
        var idToken = await getIdToken();
        request.post(`/paciente/${pacienteId}/atendimento/${atendimentoId}/sendmessageenfermeiro`,
            {
                idFrom: pacienteId,
                idTo: medicoId,
                content: msg,
                type: 0,
                notifica: true,
                replicar: true,
            }, {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        })
            .then(result => {
                console.log(result)

                try {
                    if (result.data.statusOk == true) {
                        resolve(result.data.message);
                    } else {
                        reject(result.data.message);
                    }
                } catch (e) {
                    reject("Erro ao mandar chat");

                }
            }
            )
            .catch(error => {
                reject("Erro ao chamar inicio de atendimento");
            })
    })
}


export const chatAddMessage = async (medicoId, pacienteId, atendimentoId, msg) => {

    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        request.post(`/paciente/${pacienteId}/atendimento/${atendimentoId}/sendmessagemedico`,
            {
                idFrom: pacienteId,
                idTo: medicoId,
                content: msg,
                type: 0,
                notifica: true,
                replicar: true,
            },
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(result => {
                console.log(result)
                try {
                    if (result.data.statusOk == true) {
                        resolve(result.data.message);
                    } else {
                        reject(result.data.message);
                    }
                } catch (e) {
                    reject("Erro ao mandar chat");

                }
            }
            )
            .catch(error => {
                reject("Erro ao mandar chat");
            })
    })
}
