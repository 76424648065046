import { SOLICITAR_ATENDIMENTO_SET_FIELD, SOLICITAR_ATENDIMENTO_SET_CURRENT} from '../actions';
import SolicitarConsulta from '../model/SolicitarConsulta';

const INITIAL_STATE = new SolicitarConsulta().voidConstructor();

export default function solicitarConsultaReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SOLICITAR_ATENDIMENTO_SET_CURRENT:
			const newState1 = new SolicitarConsulta().voidConstructor();
			return newState1;
		case SOLICITAR_ATENDIMENTO_SET_FIELD:
			const newState2 = {...state};
			newState2[action.field] = action.value;
			return newState2;
		default:
			return state;
	}
}
