import axios from 'axios';
import firebase from 'firebase';
import Cripto from '../components/cripto.js';

export * from './loginActions.js';
export * from './utils.js';
export * from './notificacoesActions.js';
export * from './chatActions.js';
export * from './perfilActions.js';
export * from './medicamentosActions.js';
export * from './comorbidadesActions.js';
export * from './pacientesActions.js';
export * from './alergiasActions.js';
export * from './cirurgiasActions.js';
export * from './examesActions.js';
export * from './consultaActions.js';
export * from './dependenteActions.js';
export * from './SolicitarConsultaActions.js';
export * from './newUserRepositorio.js';

export const  getIdToken = async () =>  {
    try{
      return new Promise(async (resolve, reject) => {
        const unsubscribe = firebase
          .auth()
          .onAuthStateChanged(async user => {
             const refreshedToken = await user
                 .getIdToken(true)
                 .catch(err => console.error(err))
             resolve(refreshedToken)
        }, reject)
     });
    }catch(error){
        console.log(error)
    }
  }

export const request = axios.create({
    baseURL: `${process.env.REACT_APP_URL_FUNCTIONS_ADDRESS}api`,
    headers: {
        'Accept': '*/*',
        'ContentType': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }
})