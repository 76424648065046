import { MEDICAMENTO_SET_FIELD, 
    MEDICAMENTO_SET_CURRENT } from '../actions';

const INITIAL_STATE = {
    disponivel: false,
    dose: '',
    idPaciente: '',
    isDependente: '',
    nome: '',
    observacao: '',
    tipo: '',
}

export default function medicamentoReducer(state = INITIAL_STATE, action) {
switch (action.type) {
   case MEDICAMENTO_SET_CURRENT:
       return action.medicamento;
   case MEDICAMENTO_SET_FIELD:
       const newState = { ...state };
       newState[action.field] = action.value;
       return newState;
   default:
       return state;
}
}