import {
    NOTIFICACOES_SET_LIST,
    NOTIFICACOES_OUTRAS_SET_LIST,
    NOTIFICACOES_CLEAR
} from "../actions/";

const INITIAL_STATE = {
    notificacoesList:[],
    notificacoesOutrasList:[],
}

export default function notificacoesReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case NOTIFICACOES_SET_LIST:
            const newState = { ...state, notificacoesList : action.value};
            return newState;
        case NOTIFICACOES_OUTRAS_SET_LIST:
            const newState2 = { ...state, notificacoesOutrasList : action.value};
            return newState2;
        case NOTIFICACOES_CLEAR:
            const newState3 = { ...state, notificacoesList : [], notificacoesOutrasList : []};
            return newState3;
        default:
            return state;
    }
}