import { ALERGIA_SET_FIELD, 
    ALERGIA_SET_CURRENT } from '../actions';

const INITIAL_STATE = {
    disponivel: false,
    idPaciente: '',
    isDependente: '',
    nome: '',
    observacao: '',
    tipo: '',
    url: ''
}

export default function alergiaReducer(state = INITIAL_STATE, action) {
switch (action.type) {
   case ALERGIA_SET_CURRENT:
       return action.alergia;
   case ALERGIA_SET_FIELD:
       const newState = { ...state };
       newState[action.field] = action.value;
       return newState;
   default:
       return state;
}
}