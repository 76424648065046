import firebase from '../config/firebase';
import Cripto from '../components/cripto'
import 'firebase/auth';
import 'firebase/firestore';

import { store } from '../../src/index.js'
import { dispatch } from 'rxjs/internal/observable/range';

export const PERFIL_LISTAR = 'PERFIL_LISTAR';
const perfilListar = (perfis) => ({
    type: PERFIL_LISTAR,
    perfis: perfis,
});

export const LISTAR_PERFIS_SELECT = 'LISTAR_PERFIS_SELECT';
const listarPerfisSelect = (field, value) => ({
    type: LISTAR_PERFIS_SELECT,
    field,
    value,
});


export const PERFIL_SET_FIELD = 'PERFIL_SET_FIELD';
export const perfilSetField = (field, value) => {
    return {
        type: PERFIL_SET_FIELD,
        field,
        value,
    }
}

export const PERFIL_SET_CURRENT = 'PERFIL_SET_CURRENT';
const perfilSetCurrent = perfil => ({
    type: PERFIL_SET_CURRENT,
    perfil
});

export const perfilSetAtual = (id) => dispatch => {

    if (id == null) {
        var perfil = [

        ]
        dispatch(perfilSetCurrent(perfil));
    } else {
        var currentValue = store.getState().perfis;
        let perfil = '';


        currentValue.map((item) => {
            if (item.id === id) {
                perfil = item;
            }
        });
        Cripto.EncryptedStorage('perfis_telas', JSON.stringify(perfil));

        dispatch(perfilSetCurrent(perfil));
    }

}




export const perfilCriar = (nomePerfil, menu, submenu) => {
    var db = firebase.firestore();
    try {
        db.collection('perfilWeb').doc(nomePerfil).set({
            menu: menu,
            submenu: submenu,
            tipo: nomePerfil
        });
        return true;
    } catch (e) {
        return false;
    }
}

export const perfilAtualizar = (nomePerfil, menu, submenu) => {
    var db = firebase.firestore();
    try {
        db.collection('perfilWeb').doc(nomePerfil).update({
            menu: menu,
            submenu: submenu,
            tipo: nomePerfil
        });

        return true;
    } catch (e) {
        return false;
    }
}


export const perfilGetListaToGrid = () => {
    return async dispatch => {
        var id = Cripto.DecryptedStorage('uid');
        var snapshot = await firebase.firestore().collection('perfilWeb').get();
        var item = [];
        snapshot.docs.map(doc => {
            let tt = {
                id: doc.id,
                menu: doc.data().menu.toString().substring(0, 20) + "[....]",
                submenu: doc.data().submenu.toString().substring(0, 20) + "[....]"
            }
            item.push(tt);
        });
        //        Cripto.EncryptedStorage('perfis_telas', JSON.stringify(item));
        dispatch(perfilListar(item));
    }
}

export const perfilGetPerfil = async (id) => {
    var snapshot = await firebase.firestore().collection('perfilWeb').doc(id).get();
    let tt = {}
    tt = {
        id: snapshot.id,
        menu: snapshot.data().menu,
        submenu: snapshot.data().submenu
    }
    return tt;
}
export const perfilGetUser = async (perfil, cpf, admin) => {
    try {
        if (admin) {
            return await new Promise(async (resolve, reject) => {
                try {
                    let query = await firebase.firestore().collection('medicos').where('cpf', '==', cpf).limit(1).get();
                    let item = [];
                    let itemWithIDPerfil = [];
                    if (!query.empty) {
                        query.docs.map(
                            doc => {
                                let docId = {
                                    id: doc.id,
                                    nome: doc.data().nome
                                }
                                item.push(docId)
                            },
                        );
                        let queryUsuario = await firebase.firestore().collection('usuarios').doc(item[0].id).collection('perfilWeb').get();
                        if (!queryUsuario.empty) {
                            queryUsuario.docs.map(
                                doc => {
                                    item.forEach(item => {
                                        let docPerfil = {
                                            id: item.id,
                                            nome: item.nome,
                                            idPerfil: doc.id
                                        }
                                        itemWithIDPerfil.push(docPerfil)
                                    })
                                },
                                resolve(itemWithIDPerfil)
                            )
                        } else {
                            item.forEach(item => {
                                let docPerfil = {
                                    id: item.id,
                                    nome: item.nome,
                                    idPerfil: ''
                                }
                                itemWithIDPerfil.push(docPerfil)
                                resolve(itemWithIDPerfil)
                            })
                        }
                    } 
                    query = await firebase.firestore().collection('administradores').where('cpf', '==', cpf).limit(1).get();
                    item = [];
                    itemWithIDPerfil = [];
                    if (!query.empty) {
                        query.docs.map(
                            doc => {
                                let docId = {
                                    id: doc.id,
                                    nome: doc.data().nome
                                }
                                item.push(docId)
                            },
                        );
                        let queryUsuario = await firebase.firestore().collection('usuarios').doc(item[0].id).collection('perfilWeb').get();
                        if (!queryUsuario.empty) {
                            queryUsuario.docs.map(
                                doc => {
                                    item.forEach(item => {
                                        let docPerfil = {
                                            id: item.id,
                                            nome: item.nome,
                                            idPerfil: doc.id
                                        }
                                        itemWithIDPerfil.push(docPerfil)
                                    })
                                },
                                resolve(itemWithIDPerfil)
                            )
                        } else {
                            item.forEach(item => {
                                let docPerfil = {
                                    id: item.id,
                                    nome: item.nome,
                                    idPerfil: ''
                                }
                                itemWithIDPerfil.push(docPerfil)
                                resolve(itemWithIDPerfil)
                            })
                        }
                    }
                    query = await firebase.firestore().collection('operadores').where('cpf', '==', cpf).limit(1).get();
                    item = [];
                    itemWithIDPerfil = [];
                    if (!query.empty) {
                        query.docs.map(
                            doc => {
                                let docId = {
                                    id: doc.id,
                                    nome: doc.data().nome
                                }
                                item.push(docId)
                            },
                        );
                        let queryUsuario = await firebase.firestore().collection('usuarios').doc(item[0].id).collection('perfilWeb').get();
                        if (!queryUsuario.empty) {
                            queryUsuario.docs.map(
                                doc => {
                                    item.forEach(item => {
                                        let docPerfil = {
                                            id: item.id,
                                            nome: item.nome,
                                            idPerfil: doc.id
                                        }
                                        itemWithIDPerfil.push(docPerfil)
                                    })
                                },
                                resolve(itemWithIDPerfil)
                            )
                        } else {
                            item.forEach(item => {
                                let docPerfil = {
                                    id: item.id,
                                    nome: item.nome,
                                    idPerfil: ''
                                }
                                itemWithIDPerfil.push(docPerfil)
                                resolve(itemWithIDPerfil)
                            })
                        }
                    }
                    reject('Usuário não encontrado')
                    
                } catch (error) {
                    reject('Usuário não encontrado')
                }
            })
        } else {
            return await new Promise(async (resolve, reject) => {
                try {
                    let query = await firebase.firestore().collection(perfil).where('cpf', '==', cpf).limit(1).get();
                    let item = [];
                    let itemWithIDPerfil = [];
                    if (!query.empty) {
                        query.docs.map(
                            doc => {
                                let docId = {
                                    id: doc.id,
                                    nome: doc.data().nome
                                }
                                item.push(docId)
                            },
                        );
                        let queryUsuario = await firebase.firestore().collection('usuarios').doc(item[0].id).collection('perfilWeb').get();
                        if (!queryUsuario.empty) {
                            queryUsuario.docs.map(
                                doc => {
                                    item.forEach(item => {
                                        let docPerfil = {
                                            id: item.id,
                                            nome: item.nome,
                                            idPerfil: doc.id
                                        }
                                        itemWithIDPerfil.push(docPerfil)
                                    })
                                },
                                resolve(itemWithIDPerfil)
                            )
                        } else {
                            item.forEach(item => {
                                let docPerfil = {
                                    id: item.id,
                                    nome: item.nome,
                                    idPerfil: ''
                                }
                                itemWithIDPerfil.push(docPerfil)
                                resolve(itemWithIDPerfil)
                            })
                        }
                    } else {
                        reject('Usuário não encontrado')
                    }
                } catch (error) {
                    reject('Usuário não encontrado')
                }
            })
        }
    } catch (error) {
    }
}

export const carregaListaPerfisDisponiveis = () => {
    try {
        return async dispatch => {
            try {
                var snapshot = await firebase.firestore().collection('perfilWeb').get();
                var listPerfis = [];
                snapshot.docs.map(doc => {
                    let tt = { value: doc.id, label: doc.id, color: '#00B8D9' };
                    listPerfis.push(tt);
                });
                dispatch(listarPerfisSelect("perfilList", listPerfis));
            } catch (e) {

            }
        }
    } catch (error) {

    }
}
export const atualizarPerfilPorUsuario = async (idUsuario, idPerfilWebAtual, idPerfilWeb, menu, submenu) => {
    try {
        return await new Promise((resolve, reject) => {
            if (idPerfilWebAtual != "") {
                firebase.firestore()
                    .collection('usuarios')
                    .doc(idUsuario)
                    .collection('perfilWeb')
                    .doc(idPerfilWebAtual).delete().then((success) => {
                        firebase.firestore()
                            .collection('usuarios')
                            .doc(idUsuario)
                            .collection('perfilWeb')
                            .doc(idPerfilWeb)
                            .set({
                                menu: menu,
                                submenu: submenu,
                                tipo: idPerfilWebAtual
                            }).then((success) => {
                                resolve('sucesso')
                            }).catch((error) => {
                                reject('error')
                            });
                    }).catch((error) => {
                        reject('error')
                    })
            } else {
                firebase.firestore()
                    .collection('usuarios')
                    .doc(idUsuario)
                    .collection('perfilWeb')
                    .doc(idPerfilWeb)
                    .set({
                        menu: menu,
                        submenu: submenu,
                    }).then((success) => {
                        resolve('sucesso')
                    }).catch((error) => {
                        reject('error')
                    });
            }
        });
    } catch (error) {
    }
}




export const atualizarPerfilTodosUsuario = async (idPerfilWebAtual, menu, submenu) => {
    try {
        let querySnapshot = await firebase.firestore().collectionGroup('perfilWeb').where('tipo', '==', idPerfilWebAtual).get();
        querySnapshot.forEach((doc) => {
            doc.ref.set({
                menu: menu,
                submenu: submenu,
                tipo: idPerfilWebAtual
            })
        });

    } catch (error) {
    }
}



export const deletePerfilPorUsuario = async (idUsuario, idPerfilWeb) => {
    try {
        return await new Promise((resolve, reject) => {

        });
    } catch (error) {

    }
}
