// src/i18n.js
// src/i18n.js
import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Cripto from './components/cripto'
var idioma = Cripto.DecryptedStorage('idioma')
if(idioma === '' || idioma === undefined || idioma === null){
i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "pt",
    debug: true,
    interpolation: {
      escapeValue: false
    }
  });
}else{
    i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next)
  .init({
    fallbackLng: idioma,
    debug: true,

    interpolation: {
      escapeValue: false
    }
  });
}
export default i18n;