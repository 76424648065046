import firebase from '../config/firebase';
import DependenteModel from '../model/dependenteModel';
import 'firebase/auth';
import 'firebase/firestore';
import Cripto from '../components/cripto'

export const dependentesLista = async (idPaciente, that ) => {
    try{
        var snapshot = await firebase.firestore().collection('pacientes').doc(idPaciente).collection('dependentes').get();
        var itens = [];
        snapshot.docs.map(doc => {
            let tt = {...doc.data(), id: doc.id}
            itens.push(tt);
        });
        console.log('dependentesLista')
        console.log(itens) 
        if(that != undefined && that != null){
            that.callbackGetDependentes(itens);
        }
        Cripto.EncryptedStorage('dependentes', JSON.stringify(itens));
    }
    catch(ex){
        console.log('dependentesLista ERROR')
        console.log(ex.message)
    }

}