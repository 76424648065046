class User {
    constructor() {

    }
    voidConstructor() {
        return {
            pushToken: "",
            nomeMae: "",
            somNotif: "",
            selfie: "",
            idAtendimento: "",
            nome: "",
            rg: "",
            profissao: "",
            statusPaciente: '',
            email: "",
            notificacao: false,
            fotoPerfil: "",
            sexo: "",
            replicar: false,
            cpf: "",
            isUserAutenticacao: true,
            dataNasc: "",
            celular: "",
            deviceIdLogin: "",
            somNotificacaoMensagem: true,
            somNotificacao: true,
            isHistoricoAtendimento: true,
            notificacaoAtendimento: false,
            isPermitirCID: false,
            documento: "",
            idiomaValueSigla: "",
            dependentes: [],
        }
    }
}
export default User;