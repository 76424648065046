import { COMORBIDADES_LISTAR	} from '../actions';

const INITIAL_STATE = {
    comorbidades : {}
}

export default function comobirdadesListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case COMORBIDADES_LISTAR:
            return action.comorbidades;
		default:
			return state;
	}
}


