import {
	ATENDIMENTOS_LISTAR_CLIENTES, ATENDIMENTO_SET_CURRENT	} from '../actions';
import SolicitarConsulta from '../model/SolicitarConsulta';

const INITIAL_STATE = {
	atendimentos : [],
	atendimento: {}
}

export default function dashboardReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case ATENDIMENTOS_LISTAR_CLIENTES:
			const newState1 = { ...state, atendimentos : action.atendimentos};
			return newState1;
		case ATENDIMENTO_SET_CURRENT:
			const newState2 = {...state};
			newState2.atendimento = action.value;
			return newState2
		default:
			return state;
	}
}
