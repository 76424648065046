import { combineReducers } from 'redux';

import reducer from '../store/reducer.js';
import userReducer from './usuarioReducer.js';

import consultaListarReducer from './consultaListarReducer.js';

import notificacaoesReducer from './notificacoesReducer.js';

import listChatMessages from './chatReducer.js';

import medicamentosPacientesListarReducer from './medicamentosPacientesListarReducer.js';
import medicamentoPacientesReducer from './medicamentoPacienteReducer.js';

import alergiasPacientesListarReducer from './alergiasPacienteListarReducer.js';
import alergiaPacientesReducer from './alergiaPacienteReducer.js';

import cirurgiaPacientesListarReducer from './cirurgiasPacienteListar.js';
import cirurgiasPacienteReducer from './cirurgiasPacienteReducer.js';

import comorbidadesListarReducer from './comorbidadesListarReducer.js';

import examePacienteListarReducer from './examesPacientesListarReducer.js';
import examePacienteReducer from './examesPacientesReducer.js';
import SolicitarConsulta from './SolicitarConsultaReducer.js';
import createUserReducer from './newUserReducer.js';

/* COLOCAR AS DEMAIS RECUCER */
export default combineReducers({
    native: reducer,
    user: userReducer,

    notificacoes: notificacaoesReducer,

    consultas: consultaListarReducer,
    
    medicamentos: medicamentosPacientesListarReducer,
    medicamento: medicamentoPacientesReducer,

    alergias: alergiasPacientesListarReducer,
    alergia: alergiaPacientesReducer,

    cirurgias: cirurgiaPacientesListarReducer,
    cirurgia: cirurgiasPacienteReducer,
    
    exames: examePacienteListarReducer,
    exame: examePacienteReducer,
    
    chat : listChatMessages,
    solicitarConsulta: SolicitarConsulta,
    comorbidades : comorbidadesListarReducer,
    newUser: createUserReducer,
});