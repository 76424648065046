import { storage } from '../config/firebasestorage';
import { getIdToken, request } from ".";
import { store } from "..";
import NewUser from "../model/NewUser";
import Cripto from '../components/cripto';
export const NEW_USER_SET_FIELD = 'NEW_USER_SET_FIELD';
export const newUserSetField = (field, value) => {
    return {
        type: NEW_USER_SET_FIELD,
        field,
        value,
    };
};

export const NEW_USER_SET_CURRENT = 'NEW_USER_SET_CURRENT';
export const newUserSetCurrent = (user) => {
    return {
        type: NEW_USER_SET_CURRENT,
        user,
    };
};


export const ValidarCliente = async () => {
    var user = new NewUser().createConstructor(store.getState().newUser);
    return await new Promise(async (resolve, reject) => {
        request.post(`/pacientevalidar`, user,)
            .then((resp) => {
                if (resp.data.statusOk) {
                    resolve(resp.data.data);
                } else {
                    reject(resp.data.message);
                }
                //"notValidator_CLIENTE"
                //"notFound_CLIENTE"
                //"jaExiste_CLIENTE"
            }).catch((error) => {
                reject("notFound_CLIENTE");
            });
    });
}

export const saveFotoUrl = async (id, url, fieldName) => {
    return await new Promise(async (resolve, reject) => {
        try {
            if (fieldName === 'selfie') {
                request.post(`/updatefotoselfie`, {
                    "fotoSelfie": url,
                    "pacienteId": id,
                })
                    .then((resp) => {
                        if (resp.data.statusOk) {
                            resolve();
                        } else {
                            reject();
                        }
                    }).catch((error) => {
                        console.log(error)
                        reject();
                    });
            } else {
                request.post(`/updatefotodocumento`, {
                    "fotoDocumento": url,
                    "pacienteId": id,
                })
                    .then((resp) => {
                        if (resp.data.statusOk) {
                            resolve();
                        } else {
                            reject();
                        }
                    }).catch((error) => {
                        console.log(error)
                        reject();
                    });
            }
        } catch (error) {
            console.log(error)
            reject()
        }
    })
}
export const uploadFoto = async (id, foto, fieldName) => {
    return await new Promise((resolve, reject) => {
        try {
            var today = new Date();
            // N0ME DO ARQUIVO COMPOSTO PELO ID DO USUARIO, MAIS NOME DO ARQUIVO, MAIS DIA E HORA
            const uploadTask = storage.ref("pacientes").child(id).child("profile").child(fieldName).put(foto);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                },
                error => {
                    console.log(error)
                    reject()
                },
                () => {
                    storage
                        .ref(`pacientes/${id}/profile`)
                        .child(fieldName)
                        .getDownloadURL()
                        .then(url => {
                            resolve(url)
                        });
                }
            );
        } catch (error) {
            console.log(error)
            reject()
        }
    })
}
export const clearNewUser = () => dispatch => {
   return dispatch(newUserSetCurrent(null))
}
export const CreateCliente = async (email, senha) => {
    return await new Promise(async (resolve, reject) => {
        var user = store.getState().newUser
        request.post('/paciente',
            {
                "celular": user.celular,
                "cpf": user.cpf,
                "dataNasc": user.dataNasc,
                "email": user.email,
                "fotoPerfil": user.fotoPerfil,
                "idAtendimento": user.idAtendimento,
                "idiomaValueSigla": user.idiomaValueSigla,
                "isHistoricoAtendimento": user.isHistoricoAtendimento,
                "isPermitirCID": user.isPermitirCID,
                "isUserAutenticacao": true,
                "nome": user.nome,
                "nomeMae": user.nomeMae,
                "notificacao": user.notificacao,
                "notificacaoAtendimento": user.notificacaoAtendimento,
                "profissao": user.profissao,
                "replicar": user.replicar,
                "rg": user.rg,
                "sexo": user.sexo,
                "somNotif": "",
                "somNotificacao": true,
                "somNotificacaoMensagem": true,
                "statusPaciente": 0,
                "password": user.password,
                "color": user.color,
            }
        )
            .then((resp) => {
                if (resp.data.statusOk) {
                    Cripto.EncryptedStorage("nome", user.nome);
                    Cripto.EncryptedStorage("uid", resp.data.statusOk);
                    resolve(resp.data.data);
                } else {
                    if (resp.data.data === 'The email address is already in use by another account.') {
                        reject(resp.data.data);
                    }
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject();
            });
    });
}