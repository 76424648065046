import { NEW_USER_SET_FIELD, NEW_USER_SET_CURRENT	} from '../actions';
import NewUser from '../model/NewUser';

const INITIAL_STATE = new NewUser().voidConstructor();

export default function createUserReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case NEW_USER_SET_FIELD:
            const newState = {...state}
            newState[action.field] = action.value;
            return newState;
        case NEW_USER_SET_CURRENT: 
            return INITIAL_STATE;
		default:
			return state;
	}
}