import firebase from '../config/firebase';
import Cripto from '../components/cripto'
import 'firebase/auth';
import 'firebase/firestore';

import { store } from '../index.js'
import { docData } from 'rxfire/firestore';
import { userSetField } from './loginActions';
import User from '../model/User';

export const DEPENDENTES_LISTAR = "DEPENDENTES_LISTAR";
export const dependentesListar = (dependentes) => ({
    type: DEPENDENTES_LISTAR,
    dependentes,
});

export const dependentesList = (idCliente) => {
    try {
        return async dispatch => {
            var id = Cripto.DecryptedStorage('idPaciente');
            var dependentes = await firebase.firestore().collection('pacientes').doc(id).collection('dependentes').get()
            var listDependentes = [];
            if (dependentes.docs.length != 0) {
                dependentes.docs.map(doc => {
                    if (doc.data().ativo) {
                        if (listDependentes === 'Sem dependentes') {
                            listDependentes = []
                        }
                        listDependentes.push(
                            {
                                ...doc.data(), id: doc.id
                            }
                        );
                    } else {
                        if (listDependentes.length === 0) {
                            listDependentes = 'Sem dependentes'
                        }
                    }
                });
            } else {
                listDependentes = 'Sem dependentes'
            }
            console.log(listDependentes)
            dispatch(dependentesListar(listDependentes));
        }
    } catch (error) {
        console.log(error)
    }
}

export const pacienteToStorageToSelect = async (idCliente) => {

    var id = idCliente;
    var snapshot = await firebase.firestore().collection('pacientes').doc(id).collection('dependentes').get();
    var itens = [];
    snapshot.docs.map(doc => {
        if (doc.data().ativo === true) {
            let tt = {
                color: '#00B8D9',
                label: doc.data().nome,
                value: doc.id
            };
            itens.push(tt);
        }
    });
    Cripto.EncryptedStorage('dependentesToSelect', JSON.stringify(itens));
}


export const salvarIdiomaELayout = async () => {
    var uid = Cripto.DecryptedStorage('uid');
    var idioma = store.getState().user.config.idioma;
    var layout = store.getState().user.config.layout;
    var isHistoricoAtendimento = store.getState().user.config.isHistoricoAtendimento;
    var db = firebase.firestore();
    return await new Promise((resolve, reject) => {
        db.collection('pacientes').doc(uid).update({
            idiomaValueSigla: idioma,
            isHistoricoAtendimento: isHistoricoAtendimento
        }).then((result) => {
            db.collection('usuarios').doc(uid).update({
                layout: layout,
            }).then((result) => {
                Cripto.EncryptedStorage('color_cliente', layout)
                Cripto.EncryptedStorage('idioma', idioma)
                resolve(true)
            }).catch((error) => {
                console.log(error)
                reject(false);
            })
        })
    });
}

export const getDependentes = async (uid) => {
    return await new Promise(async (resolve, reject) => {
        try {
            var arrDependentes = [];
            var snapshot = await firebase.firestore().collection('pacientes').doc(uid).collection('dependentes').get();
            await Promise.all(snapshot.docs.map(async (doc) => {
                let tt = { ...doc.data(), id: doc.id }
                await arrDependentes.push(tt);
            }));
            resolve(arrDependentes);
        } catch (error) {
            resolve([]);
        }
    });
}
export const GetDadosUsuarioPerfilEdit = () => {
    var uid = Cripto.DecryptedStorage('uid');
    return async dispatch => {
        try {
            var dadosUser = await firebase
                .firestore()
                .collection("pacientes")
                .doc(uid).get();
            dispatch(userSetField("usuario", dadosUser.data()));
        } catch (error) {
            console.log(error)
            var usuario = new User;
            dispatch(userSetField("usuario", usuario.voidConstructor()))
        }
    }
};
export const GetDadosUsuario = () => {
    var uid = Cripto.DecryptedStorage('uid');
    return async dispatch => {
        try {
            const docRef = firebase
                .firestore()
                .collection("pacientes")
                .doc(uid);
            docData(docRef).subscribe(async (doc) => {
                if (doc.statusPaciente > 0) {
                    Cripto.EncryptedStorage('idAtendimento', doc.idAtendimento);
                }
                var arrDependentes = await getDependentes(uid);
                doc.dependentes = arrDependentes;
                dispatch(userSetField("usuario", doc));
            }
            );
        } catch (error) {
            console.log(error)
            var usuario = new User;
            dispatch(userSetField("usuario", usuario.voidConstructor()))
        }
    }
};