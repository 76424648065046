import Cripto from '../components/cripto'
import firebase from '../config/firebase';
import 'firebase/auth';
import 'firebase/firestore';
import { docData } from "rxfire/firestore";
import Log from '../components/log'
import { store } from '../../src/index.js'
import { storage } from '../config/firebasestorage';
import { getColor } from '../utils/getFoto';
import { replaceEspace } from '../utils/utils';

firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
        Cripto.EncryptedStorage('logado', 'true');
        Cripto.EncryptedStorage('token', user.refreshToken); // usuario da maquina esta na storage!!! (obrigatorio!)
        await userPerfilToken(Cripto.DecryptedStorage('token'));
        //alert('setou token!: ['+ user.refreshToken + "]");
        return async (dispatch) => {
            //dispatch(userSetDados("token", user.refreshToken));
            alert('token setou')
        }
        //userPerfilToken();
    } else {
        if (window.location.toString().toLowerCase().includes("login")) {
            Cripto.EncryptedStorage('logado', 'false');
        } else {
            window.location.assign("/login")
        }
    }
});



export const verificarUsuarioLogadoFirebase = () => {
    if (Cripto.DecryptedStorage('logado') === 'true')
        return true;
    else
        return false;
}

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
const userLoginSuccess = user => ({
    type: USER_LOGIN_SUCCESS,
    user
});

export const USER_LOGOUT = 'USER_LOGOUT';
const userLogout = () => ({
    type: USER_LOGOUT,
});

export const USER_SET_FIELD = 'USER_SET_FIELD';
export const userSetField = (field, value) => {
    return {
        type: USER_SET_FIELD,
        field,
        value,
    }
}
export const USER_SET_FIELD_DADOS = 'USER_SET_FIELD_DADOS';
export const userSetFieldDados = (field, value) => {
    return {
        type: USER_SET_FIELD_DADOS,
        field,
        value,
    }
}

export const USER_SET_FIELD_CONFIG = 'USER_SET_FIELD_CONFIG';
export const userSetFieldConfig = (field, value) => {
    return {
        type: USER_SET_FIELD_CONFIG,
        field,
        value,
    }
}




export const USER_NOTIFICACOES_PACIENTE = 'USER_NOTIFICACOES_PACIENTE';
export const userNotificacoesPaciente = (value) => ({
    type: USER_NOTIFICACOES_PACIENTE,
    value,
});




export const userPerfilToken = async (token) => {


    try {
        var uid = Cripto.DecryptedStorage('uid');
        var snapshot = await firebase.firestore().collection('usuarios').doc(uid).update({
            token: token
        })
    } catch (ex) {
    }
}
export const userSetCookies = async (token) => {

    var uid = Cripto.DecryptedStorage('uid');
    try {
        ////pegar do stoFInrage
        var snapshot = await firebase.firestore().collection('usuarios').doc(uid).update({
            cookies: true
        })
        Cripto.EncryptedStorage('cookies', true);

    } catch (ex) {
    }
}


export const verificarLoginComParam = (newToken) => {
    if (newToken != null) {
        if (newToken != Cripto.DecryptedStorage('token') && newToken != '') {
            if (Cripto.DecryptedStorage('token') != newToken) {
                var uid = Cripto.EncryptedStorage('uid', '');
                alert("Seu usuário acabou de executar login em outro navegador!")
                window.location.assign('/login');
                return
            }
        }
    }
}

export const userObservableToken = () => {
    var uid = Cripto.DecryptedStorage('uid');
    if (uid == "" || uid == undefined || uid == null) {

    } else {
        const docRef = firebase
            .firestore()
            .collection("usuarios")
            .doc(uid);
        docData(docRef).subscribe((doc) => {
            return async dispatch => {
                dispatch(userSetField("token", doc.token));
            }
        });
    }
};

export const logOut = async () => {
    await firebase
        .auth()
        .signOut()
        .then(() => {
            Cripto.EncryptedStorage('email', '');
            Cripto.EncryptedStorage('perfil', '');
            Cripto.EncryptedStorage('dependentes_cadastrar', '');
            Cripto.EncryptedStorage('id_convenio', '');
            Cripto.EncryptedStorage('convenioSelected', '');
            Cripto.EncryptedStorage('permissao_telas_usuario', '');
            Cripto.EncryptedStorage('collapsed_menu', 'false');
            Cripto.EncryptedStorage('color_cliente', "")
            Cripto.EncryptedStorage('idioma', "")
            Cripto.EncryptedStorage('idToken', "");
            return true;
        })
        .catch(error => {
            return Promise.reject(error)
        })
}

export const logOff = () => dispatch => {
    return (
        firebase
            .auth()
            .signOut()
            .then(() => {
                const action = userLogout();
                Cripto.EncryptedStorage('email', '');
                //   Cripto.EncryptedStorage('password', '');
                var itensLog = [];
                Cripto.EncryptedStorage('perfil', '');
                Cripto.EncryptedStorage('dependentes_cadastrar', '');
                Cripto.EncryptedStorage('id_convenio', '');
                Cripto.EncryptedStorage('convenioSelected', '');
                Cripto.EncryptedStorage('permissao_telas_usuario', '');
                Cripto.EncryptedStorage('collapsed_menu', 'false');

                dispatch(action);
                return true;
            })
            .catch(error => {
                return Promise.reject(error)
            })
    )
}

export const getDadossssssssssss = (id) => dispatch => {
    var db = firebase.firestore();
    var query = db.collection('users').doc(id).get();
    return (
        query.then(function (doc) {

            return id;
        }).catch(function (error) {

        }))
}

export const tryRedefinirSenha = (email) => dispatch => {
    return (
        firebase.auth().sendPasswordResetEmail(email)
            .then((result) => {

                return true;
            })
            .catch((error) => {

                return Promise.reject(error)
            })
    )
}

export const googleLogin = () => {
    const providerGoogle = new firebase.auth.GoogleAuthProvider();
    console.log(providerGoogle)
    firebase.auth().signInWithPopup(providerGoogle);
}
export const tryLogin = (email, password) => dispatch => {
    return (
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((user) => {
                firebase.auth().currentUser.getIdToken().then(function (idToken) {
                    Cripto.EncryptedStorage('idToken', idToken);
                    //console.log(idToken);
                }).catch(function (error) {
                    // Handle error
                });

                const action = userLoginSuccess(user);

                //  Cripto.EncryptedStorage('password', password);


                dispatch(action);


                const { currentUser } = firebase.auth();

                return user;
            })
            .catch(error => {
                return Promise.reject('emailEOuSenhaErrados')
            }
            )
    )
}
export const userPerfilDados = async () => {
    var id = Cripto.DecryptedStorage('uid')
    return await new Promise(async (resolve, reject) => {
        try {
            var perfil = await firebase.firestore().collection('usuarios').doc(id).get();
            if (perfil.exists) {
                if (perfil.data().cookies !== undefined) {
                    Cripto.EncryptedStorage('cookies', perfil.data().cookies)
                } else {
                    Cripto.EncryptedStorage('cookies', 'false')
                }
                if (perfil.data().layout !== undefined) {
                    if (perfil.data().layout == 'dark') {
                        Cripto.EncryptedStorage('color_cliente', 'dark');
                    } else {
                        Cripto.EncryptedStorage('color_cliente', 'original');
                    }
                } else {
                    Cripto.EncryptedStorage('color_cliente', 'original');
                }
                if (perfil.data().layoutSuperiorAtendimento !== undefined) {
                    if (perfil.data().layoutSuperiorAtendimento == true) {
                        Cripto.EncryptedStorage('layoutSuperiorAtendimento', 'true');
                    } else {
                        Cripto.EncryptedStorage('layoutSuperiorAtendimento', 'false');
                    }
                } else {
                    Cripto.EncryptedStorage('layoutSuperiorAtendimento', 'false');
                }
                resolve('success');
            } else {
                reject('erroDesconhecido');
            }
        } catch (error) {
            reject('erroDesconhecido');
        }
    })
};

export const setColor = async (perfilName, uid) => {
    try {
        var color = getColor();
        await firebase.firestore().collection(perfilName).doc(uid).update({
            color: color
        }).then(success => {
            console.log(color)
            Cripto.EncryptedStorage('color', color);
            return color;
        }).catch(error => {
            return error;
        })
    } catch (error) {
        console.log(error)
        return error;
    }
}

export const userPerfil = async (email, that) => {
    const { currentUser } = firebase.auth();
    Cripto.EncryptedStorage('uid', currentUser.uid);
    Cripto.EncryptedStorage('token', currentUser.refreshToken); // usuario da maquina esta na storage!!! (obrigatorio!)
    Cripto.EncryptedStorage('email', email);
    return await new Promise(async (resolve, reject) => {
        try {
            var db = firebase.firestore();
            var query = await db.collection('pacientes').doc(currentUser.uid).get();
            if (query.exists) {
                var cpf = query.data().cpf;
                var cpfSemMascara = cpf.replace('.', '');
                cpfSemMascara = cpfSemMascara.replace('-', '');
                cpfSemMascara = cpfSemMascara.replace('.', '');
                var cliente = await db.collection('clientes').doc(cpfSemMascara).get();
                if (cliente.data().ativo === false) {
                    reject('usuarioInativo');
                }
                if (query.data().ativo === false) {
                    reject('usuarioInativo');
                }
                Cripto.EncryptedStorage('nome', query.data().nome);
                Cripto.EncryptedStorage('idPaciente', query.id);
                Cripto.EncryptedStorage('nomeTitular', query.data().nome);
                Cripto.EncryptedStorage('sexoTitular', query.data().sexo);
                Cripto.EncryptedStorage('dataNascTitular', query.data().dataNasc);
                Cripto.EncryptedStorage('fotoPerfil', query.data().fotoPerfil)
                if (query.data().color === undefined) {
                    await setColor('pacientes', currentUser.uid);
                } else {
                    Cripto.EncryptedStorage('color', query.data().color);

                }
                Cripto.EncryptedStorage()
                var idioma = query.data().idiomaValueSigla;
                if (idioma === undefined || idioma === "" || idioma === "br") {
                    var idiomaNavegador = navigator.language;
                    var idiomaSplited = idiomaNavegador.split('-')[0]
                    Cripto.EncryptedStorage('idioma', idiomaSplited);
                } else {
                    Cripto.EncryptedStorage('idioma', query.data().idiomaValueSigla);
                }
                resolve('true')
            } else {
                reject('usuarioNaoEncontrado')
            }
        } catch (error) {
            reject('erroDesconhecido')
        }
    })
}


export const userPerfilSetLayout = (color) => {
    var uid = Cripto.DecryptedStorage('uid');
    var db = firebase.firestore();
    db.collection('usuarios').doc(uid).update({
        layout: color
    })
        .then((result) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
}




export const userPerfilSetLayoutSuperiorAtendimento = (ativo) => {
    var uid = Cripto.DecryptedStorage('uid');
    var db = firebase.firestore();
    db.collection('usuarios').doc(uid).update({
        layoutSuperiorAtendimento: ativo
    })
        .then((result) => {
            return true;
        })
        .catch((error) => {
            return false;
        });
}

export const userPerfilGetData = async (perfil) => {
    try {

        var uid = Cripto.DecryptedStorage('uid');

        var db = firebase.firestore();
        var perfilName = perfil.toLowerCase();
        var query = db.collection(perfilName).doc(uid).get();

        return await new Promise((resolve, reject) => {
            query.then((doc) => {
                Cripto.EncryptedStorage('convenioSelected', JSON.stringify(''));
                Cripto.EncryptedStorage('nome', doc.data().nome);
                Cripto.EncryptedStorage('fotoPerfil', doc.data().fotoPerfil);
                if (perfil == "operadores") {
                    var convenios = doc.data().convenios;
                    Cripto.EncryptedStorage('convenios', JSON.stringify(convenios));
                }

                resolve(doc.data().ativo);
            })
                .catch((error) => {
                    resolve('error');
                })
        })
    }
    catch (e) {

    }
}
export const getConfigUser = () => {
    try {
        var uid = Cripto.DecryptedStorage('uid');
        return async dispatch => {
            try {
                var doc = await firebase.firestore().collection('pacientes').doc(uid).get()
                var docPerfil = await firebase.firestore().collection('usuarios').doc(uid).get()
                let config = {};
                config.idioma = Cripto.DecryptedStorage('idioma')
                config.layout = docPerfil.data().layout;
                config.isHistoricoAtendimento = doc.data().isHistoricoAtendimento === undefined ? false : doc.data().isHistoricoAtendimento;
                dispatch(userSetField("config", config));
            } catch (e) {
            }
        }
    }
    catch (e) {

    }
}
export const handleUpload = async (foto) => {
    return await new Promise((resolve, reject) => {
        try {
            var today = new Date();
            // N0ME DO ARQUIVO COMPOSTO PELO ID DO USUARIO, MAIS NOME DO ARQUIVO, MAIS DIA E HORA
            var uid = Cripto.DecryptedStorage('uid');
            var nameFile = uid + foto.name + today.getTime();
            const uploadTask = storage.ref(`images/${nameFile}`).put(foto);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                },
                error => {
                    reject()
                },
                () => {
                    storage
                        .ref("images")
                        .child(nameFile)
                        .getDownloadURL()
                        .then(url => {
                            resolve(url)
                        });
                }
            );
        } catch (error) {
            reject()
        }
    })
};

export const salvarPerfil = async (fotoPerfil) => {
    try {
        var uid = Cripto.DecryptedStorage('uid');
        var profissao = store.getState().user.usuario.profissao;
        profissao = replaceEspace(profissao)
        var telefone = store.getState().user.usuario.celular;
        var db = firebase.firestore();
        return await new Promise((resolve, reject) => {
            db.collection('pacientes').doc(uid).update({
                fotoPerfil: fotoPerfil,
                profissao: profissao == "" ? null : profissao,
                celular: telefone == "" ? null : telefone,
            })
                .then((result) => {
                    resolve(true)
                })
                .catch((error) => {
                    reject(false);
                })
        });
    } catch (e) {
        alert(e)
    }
}



export const loginNotificacoesPaciente = (idPaciente) => {
    try {
        return async dispatch => {
            try {
                var snapshot = await firebase.firestore().collection('pacientes').doc(idPaciente).collection('notificacao').get();
                var itens = [];
                snapshot.docs.map(doc => {
                    let tt = { ...doc.data(), id: doc.id }
                    itens.push(tt);
                });
                dispatch(userNotificacoesPaciente(itens));
            }
            catch (e) {
                console.log('loginNotificacoes ERRO');
                console.log(e.message);
            }
        }
    }
    catch (e) {
        console.log('loginNotificacoes ERRO 2');
        console.log(e.message);
    }
}
